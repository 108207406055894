
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css"; // Importamos el archivo CSS personalizado


import AppRoutes from "./routes/AppRoutes";

function App() {
  return <AppRoutes />;
}

export default App;
