import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TraceInfoPage from "../components/pages/TraceInfoPage";
import HomePage from "../components/pages/HomePage";
import LocationMap from "../components/LocationMap";
const AppRoutes = () => {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<HomePage />} />
        <Route path="/products/:product_id/lots/:lot_id" element={<TraceInfoPage />} />
        <Route path="/mapa" element={<LocationMap />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
