import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Definir el ícono personalizado si lo deseas
const customIcon = new L.Icon({
  iconUrl: 'https://cdn-icons-png.flaticon.com/512/252/252025.png', // URL de un ícono público
  iconSize: [35, 41], // Tamaño del icono
  iconAnchor: [12, 41], // Posición del icono
  popupAnchor: [1, -34], // Posición del popup
  shadowSize: [41, 41] // Sombra del ícono
});

const LocationMap = ({ popupDescription, latitude, longitude }) => {
  // Definir una ubicación predeterminada
  const position = [latitude, longitude]; 

  return (
    <MapContainer center={position} zoom={6} style={{ height: '200px', width: '100%', border: '1px solid #ccc' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={position} icon={customIcon}>
        <Popup>
          <p>{popupDescription}</p>
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default LocationMap;
