import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "moment/locale/es";
import { ClipLoader } from "react-spinners";
import { FaCheckCircle, FaInfoCircle, FaUser } from "react-icons/fa";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
const EventDetails = React.lazy(() => import('../EventDetails'));
const DocumentList = React.lazy(() => import('../DocumentList'));
const LocationMap = React.lazy(() => import('../LocationMap'));

const TraceabilityPage = () => {
  const [traceInfo, setTraceability] = useState(null);
  const [event, setEvent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const { product_id, lot_id } = useParams();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        let response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/products/${product_id}/lots/${lot_id}`,
          {
            headers: {
              "x-security-code": sessionStorage.getItem("sessionCode"),
            },
          }
        );

        setIsLoading(false);
        setTraceability(response.data);

        // Filtrar eventos por tipo con prioridad
        const events = response.data.events || [];
        const commissionEvent = events.find(
          (event) => event.type === "commission"
        );
        const observationEvent = events.find(
          (event) => event.type === "observation"
        );

        // Establecer el evento prioritario
        setEvent(observationEvent || commissionEvent);
      } catch (error) {
        setIsLoading(false);

        if (error.response && error.response.status === 404) {
          setErrorMessage(`Lot number ${lot_id} not found.`);
        }
        if (error.response && error.response.status === 403) {
          navigate(
            "/?error=invalid-code&product=" + product_id + "&lot=" + lot_id
          );
        } else {
          setErrorMessage("An error occurred while fetching data.");
        }
        console.error(error);
      }
    };

    setIsLoading(true);
    fetchProducts();
  }, [product_id, lot_id]);

  return (
    <Container>
      <Row>
        <div>
          <img
            src="/logo_adecoagro_horizontal_sin_claim_color.png"
            alt="Logo de la Empresa"
            className="logo-verde-adecoagro"
          />
        </div>
      </Row>
      <div>
        {isLoading ? (
          <Row>
            <Col className="col-md-12 d-flex justify-content-center">
              <ClipLoader size={50} color={"#005234"} loading={isLoading} />
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col className="col-md-12">
                <h1 className="info-title">
                  Blockchain Traceability Information{" "}
                  {traceInfo ? " - " + traceInfo.productName : ""}
                </h1>
              </Col>
            </Row>
            <Row>
              <Col className="col-md-3">
                <div className="verified-notification">
                  <FaCheckCircle /> Blockchain verified
                </div>
              </Col>
              <Col className="col-md-9 d-flex justify-content-end">
                <Button variant="primary" onClick={() => navigate("/")}>
                  Search lot
                </Button>
              </Col>
            </Row>
            {errorMessage ? (
              <Row>
                <Col className="col-md-12">
                  <br />
                  <div className="alert alert-danger" role="alert">
                    {errorMessage}
                  </div>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col className="col-md-6">
                  <div className="info-section"  style={{ height: "450px" }}>
                    <FaInfoCircle className="icon-right" />
                    <h2>Lot Information</h2>
                    <h4 className="d-flex">
                      <span className="info-label">Lot Number:</span>
                    </h4>
                    <p id="transactionId" className="highlighted-id">
                      {traceInfo ? traceInfo.lot : "No data"}
                    </p>
                    <p className="info-label">Product:</p>
                    <p id="transactionLote" className="info-value">
                      {traceInfo ? traceInfo.productName : "No data"}
                    </p>
                    <p className="info-label">Quantity:</p>
                    <p id="transactionProductCode" className="info-value">
                      {event?.quantities?.[0]?.quantity ?? "No data"} tons
                    </p>
                  </div>
                </Col>
                <Col className="col-md-6">
                  <div className="info-section" style={{ height: "450px" }}>
                    <FaUser className="icon-right" />
                    <h2>Facility</h2>
                    <p className="info-label">Name:</p>
                    <p id="facilityName" className="info-value">
                      {event?.location?.name ?? "No data"}
                    </p>
                    <p className="info-label">Location:</p>
                    <p id="clientContract" className="info-value">
                      {event?.location?.street_address ?? ""}
                      {event?.location?.city ? ", " + event.location.city : ""}
                      {event?.location?.state
                        ? ", " + event.location.state + ", Argentina"
                        : ""}
                    </p>
                    <LocationMap popupDescription={event?.location?.name} latitude={event?.location?.latitude} longitude={event?.location?.longitude}/>
                  </div>
                </Col>

                {traceInfo?.events?.length > 0 && (
                  <EventDetails traceInfo={traceInfo} />
                )}

                {traceInfo?.documents?.length > 0 && (
                  <DocumentList documents={traceInfo.documents} />
                )}
              </Row>
            )}
          </>
        )}
      </div>
    </Container>
  );
};

export default TraceabilityPage;
