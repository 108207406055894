import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";

import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";

const HomePage = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [products, setProducts] = useState([]);
  const [isPageLoaded, setIsPageLoaded] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    // Manejar la carga completa de la página
    const handlePageLoad = () => {
      setTimeout(() => {
        setIsPageLoaded(false);
      },  1000);
    };

    // Verificar si la página ya está cargada
    if (document.readyState === "complete") {
      setIsPageLoaded(false);
    } else {
      window.addEventListener("load", handlePageLoad);
    }

    // Verifica si el código está en sessionStorage al cargar la página
    const storedCode = sessionStorage.getItem("sessionCode");
    if (storedCode) {
      setValue("code", storedCode);
    }

    // cargar combo productos
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/products`
        );
        setProducts(response.data);

        setIsLoading(false);
      } catch (error) {
        //manejar error para mostrar en la vista que no sea un alerta
        setErrorMessage("An error occurred while fetching data.");
        setIsLoading(false);
        console.error(error);
      }
    };
    fetchProducts();

    return () => window.removeEventListener("load", handlePageLoad);
  }, []);

  useEffect(() => {
    const productSelected = sessionStorage.getItem("productSelected");
    if (productSelected) {
      setValue("product", productSelected);
    }
  }, [products]);

  const onSubmit = async (data) => {
    const { product, lotNumber, code } = data;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/validate-code`,
        { code }
      );
      if (response.data.valid) {
        sessionStorage.setItem("sessionCode", code);
        sessionStorage.setItem("productSelected", product);
        navigate(`/products/${product}/lots/${lotNumberCleaner(lotNumber)}`);
      } else {
        setErrorMessage("Invalid code.");
        setIsLoading(false);
      }
    } catch (error) {
      setErrorMessage("Error validating code: " + error.message);
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleProductChange = (e) => {
    const selectedProduct = e.target.value;
    setValue("product", selectedProduct);
    sessionStorage.setItem("productSelected", selectedProduct);
  };

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  const items = [
    {
      src: "/HomePage/Siembra Directa.png",
      alt: "Farming",
      caption: "Production Farming",
      description:
        "We seek to maximize our productive capacity implementing good agricultural practices such as direct seeding, crop rotation and cover crops. As a whole sustainability is our main driver.",
    },
    {
      src: "/HomePage/3.svg",
      alt: "Logistics & Industrial",
      caption: "Logistics & Industrial",
      description:
        "To ensure innocuity and quality, we control each logistic stage thru our industrial facilities, assuring traceability and quality in whole process that is key for the end user.",
    },
    {
      src: "/HomePage/DV_IMG_3375.png",
      alt: "Final Product-Test & Traceability",
      caption: "Final Product-Test & Traceability",
      description:
        "At Adecoagro we prioritize each client’s needs. That is why we are fully integrated, we control the quality and safety of our products before and after the industrial stage.",
    },
    {
      src: "/HomePage/DV_IMG_3470.png",
      alt: "COMEX & Trading",
      caption: "COMEX & Trading",
      description:
        "We Export our products to most strict and rigorous worldwide markets enhancing our strengths since farms seeding up to containers.",
    },
  ];

  const lotNumberCleaner = (str) => {
    let lote = str.trim().toUpperCase();
    lote = lote.replace(/-/g, "");
    const firstTwoChars = lote.substring(0, 2);
    if (firstTwoChars === "LC") {
      const number = lote.slice(2, -2);
      const year = lote.slice(-2);
      lote = `LC-${number}-${year}`;
      return lote;
    }

    return str;
  };
  return (
    <div className="App">
      {isPageLoaded ? (
        <Row>
          {/* mejor spinner */}

          <Col className="col-md-12 d-flex justify-content-center">
            {/* spinner margin top   */}
            <div style={{ marginTop: "20vh" }}>
              <ClipLoader size={100} color={"#005234"} loading={isPageLoaded} />
            </div>
          </Col>
        </Row>
      ) : (
        <>
          {/* Primera Sección (Hero Section) Dividida */}
          <Container fluid>
            <Row className="header-row">
              {/* Columna Derecha */}
              <Col md={6} className="hero-section">
                <Row className="d-flex align-items-center justify-content-center hero-content">
                  <Col className="position-center">
                    {/* Texto */}
                    <div className="hero-text">
                      Integration focused <br /> on food quality
                    </div>
                    {/* Texto adicional */}
                    <p className="hero-description">
                      We are an agro industrial company that produces and
                      manufactures food and renewable energy. <br />
                      We have a strong entrepreneurial culture and we
                      continuously work towards improving every aspect of our
                      operations.
                    </p>
                    <Image
                      src="/HomePage/flechas_der.png"
                      alt="Flechas"
                      className="hero-icon"
                    />
                  </Col>
                </Row>
                <Row className="d-flex image-section">
                  <Col className="p-0">
                    <Image
                      src="HomePage/Slice 3.png"
                      alt="Second Image"
                      fluid
                      className="image-cover"
                    />
                  </Col>
                </Row>
              </Col>

              {/* Columna Izquierda */}
              <Col
                md={6}
                className="d-flex align-items-center justify-content-center full-height-section p-0"
              >
                <Image
                  src="/HomePage/logo_adecoagro_horizontal_sin_claim_blanco.png"
                  alt="Logo de la Empresa"
                  className="hero-logo"
                />
                <Image
                  src="HomePage/Slice 2.png"
                  alt="Second Image"
                  fluid
                  className="image-cover"
                />
              </Col>
            </Row>
          </Container>

          {/* Sección de contenido con carrusel */}
          <Container fluid className="">
            <Row>
              <Col md={12} className="p-0">
                <div
                  className="carousel-custom"
                  style={{ height: "100%", minHeight: "100px" }}
                >
                  {items.map((item, index) => (
                    <div
                      key={index}
                      className={`carousel-item-custom ${
                        index === activeIndex ? "active-item" : ""
                      }`}
                      onMouseOver={() => handleSelect(index)}
                    >
                      <img
                        className="carousel-image img-fluid"
                        src={item.src}
                        alt={item.alt}
                        style={{ objectFit: "cover", width: "100%" }}
                      />
                      <div className="carousel-caption-custom text-center">
                        <h5>{item.caption}</h5>
                        <h6>{item.description}</h6>
                      </div>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Container>

          {/* IBM Section */}
          <Container fluid className="py-4 bg-white text-center">
            <Row>
              <Col>
                <div className="custom-size3">
                  Traceability using IBM’s blockchain technology.{" "}
                </div>
                <Image
                  src="/HomePage/ibm_silver.png"
                  alt="IBM Food Trust Logo"
                  fluid
                  className="custom-size4"
                />
              </Col>
            </Row>
          </Container>

          <Container fluid>
            <Row>
              {/* Columna Derecha */}
              <Col md={6} className="p-0">
                <div className="transparent-supply">
                  Transparent <br /> Supply
                </div>

                <div className="image-section-transparent">
                  <Image
                    src="HomePage/Slice 1.png"
                    alt="Second Image"
                    fluid
                    className="image-cover image-transparent"
                  />
                </div>
              </Col>

              {/* Supply Section */}
              <Col md={6} className="p-0">
                <div className="p-5 form-transparent">
                  <>
                    {isLoading ? (
                      <Row>
                        <Col className="col-md-12 d-flex justify-content-center">
                          <ClipLoader
                            size={50}
                            color={"#005234"}
                            loading={isLoading}
                          />
                        </Col>
                      </Row>
                    ) : (
                      <>
                        {errorMessage && (
                          <div className="alert alert-danger" role="alert">
                            {errorMessage}
                          </div>
                        )}
                        <Form onSubmit={handleSubmit(onSubmit)}>
                          <Form.Group controlId="product">
                            <Form.Label>Product Name</Form.Label>
                            <Form.Control
                              as="select"
                              {...register("product", {
                                required: "Product is required",
                              })}
                              onChange={handleProductChange}
                              isInvalid={!!errors.product}
                            >
                              <option value="">Select a product</option>
                              {products.map((product) => (
                                <option key={product.id} value={product.id}>
                                  {product.description}
                                </option>
                              ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.product && errors.product.message}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group controlId="lotNumber">
                            <Form.Label>Lot Number</Form.Label>
                            <Form.Control
                              style={{ textTransform: "uppercase" }}
                              type="text"
                              {...register("lotNumber", {
                                required: "Lot number is required",
                              })}
                              isInvalid={!!errors.lotNumber}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.lotNumber && errors.lotNumber.message}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group controlId="code">
                            <Form.Label>Code</Form.Label>
                            <Form.Control
                              type="password"
                              {...register("code", {
                                required: "Code is required",
                              })}
                              isInvalid={!!errors.code}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.code && errors.code.message}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Button
                            variant="primary"
                            type="submit"
                            className="btn-block"
                          >
                            Search
                          </Button>
                        </Form>
                      </>
                    )}
                  </>
                </div>
              </Col>
            </Row>
          </Container>

          {/* Footer */}
          <Container
            fluid
            className="text-white text-right py-3"
            style={{ backgroundColor: "#303E48", height: "200px" }}
          >
            <Row>
              <Col className="d-flex justify-content-end">
                <Image
                  src="/HomePage/logo_adecoagro_horizontal_sin_claim_blanco.png"
                  alt="Adecoagro Logo"
                  className="footer-logo"
                  fluid
                  style={{ marginTop: "50px" }}
                />
              </Col>
            </Row>
          </Container>
        </>
      )}
    </div>
  );
};

export default HomePage;
